.highlighted-box {
    display: flex;
    align-items: center;
    border: 1px solid #ffcc00;
    background-color: #fff2cc;
    margin-bottom: 20px;
}

.highlighted-text {

    /* ensuring the text is black */
    margin-left: 20px;
    /* space between image and text */
    margin-right: 20px;
    flex: 1;
}

.highlighted-text p {
    color: black;
}

.highlighted-image {
    width: 30%;
    /* adjust the width as necessary */
    height: auto;
}

@media (max-width: 768px) {
    .highlighted-box {
        flex-direction: column;
    }

    .highlighted-image {
        width: 100%;
        margin-bottom: 20px;
    }

    
}