@import url('https: //fonts.googleapis.com/css?family=Satisfy|Poppins:300,400,500,700&amp;display=swap');


.logo {
    position: absolute;
    bottom: 0;
    left: 0;
    width: auto;
    height: 130px
}

.color1 {
    color: #8C0712;
}
.color1bright {
    color: #dd2c38;
}

.color2 {
    color: #F23D4C;
}

.color3 {
    color: #F2B705;
}

.color4 {
    color: #F29F05;
}

.color5 {
    color: #D96704;
}

.grey {
    background-color: grey;
}

.white {
    background-color: white;
    color: black;
}
.black_block {
    color: white;
    background: black;
    border: 1px solid white;
}

.cover-image {
    width: 100%;

    background-size: cover;
    background-position: center;
    position: relative;
    background-repeat: no-repeat;
    background-color: #F2B705;
}

.cover-image .text {
    color: white;
    font-family: "Satisfy";
    font-size: 5em;
    line-height: 130%;
    text-align: center;
    margin-top: 1em;
    margin-bottom: 2em;
    margin: 1.2em 1em 2em 1em;
}

@media only screen and (max-width: 480px) {
    .cover-image {
        background-size: contain;
        height: 29.5vh !important;

        /* Ensure it fits the container */
    }

    .logo {
        /* display: none; */
        position: absolute;
        bottom: 0;
        left: 0;
        width: auto;
        height: 70px;
    }

    .cover-image .text {
        color: white;
        font-family: "Satisfy";
        font-size: 2em;
        line-height: 130%;
        text-align: center;
        margin-top: 1em;
        margin-bottom: 2em;
        margin: 0.8em 0.4em 0.8em 0.4em;
    }

}

.fonts {
    font-family: "Poppins";
}

p,
footer {
    color: white;
}