.footer {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #8C0712;
    /* Using brand color */
}
.footer div p {
    text-align: center;
    
}
.footer a {
    color: white;
}
.footer-icons {
    display: flex;
    gap: 20px;
    /* Adds space between icons */
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #F2B705;
    /* Using brand color */
    color: white;
    text-decoration: none;
    font-size: 24px;
}

.icon:hover {
    background-color: #F29F05;
    /* Darker color on hover */
}